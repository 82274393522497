import { API_V2 } from "@/config/api";
import axios, { AxiosError, AxiosRequestConfig, ResponseType } from "axios";
import {
  IAuthTokens,
  TokenRefreshRequest,
  applyAuthTokenInterceptor,
  clearAuthTokens,
  getBrowserLocalStorage,
} from "axios-jwt";

export type Method = "GET" | "POST" | "PUT" | "DELETE";

export interface Route {
  method: Method;
  url: string;
}

const axiosInstance = axios.create({ baseURL: API_V2 });
const noAuthAxiosInstance = axios.create({ baseURL: API_V2 });

const requestRefresh: TokenRefreshRequest = async (
  refreshToken: string
): Promise<IAuthTokens | string> => {
  try {
    const response = await axios.post(
      `${API_V2}/auth/refresh`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );

    return {
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
    };
  } catch (e) {
    console.error(e);
    clearAuthTokens();
    return "";
  }
};

applyAuthTokenInterceptor(axiosInstance, { requestRefresh });

const getStorage = getBrowserLocalStorage;

applyAuthTokenInterceptor(axiosInstance, {
  requestRefresh,
  getStorage,
});

export interface RequestData {
  data?: Record<string, any> | FormData;
  headers?: Record<string, string>;
  method: Method;
  params?: Record<string, string>;
  noAuth?: boolean;
  responseType?: ResponseType;
}

const apiRequest = async <T>(
  route: string,
  request: RequestData
): Promise<T> => {
  const isFileUpload = request.data instanceof FormData;

  const options: AxiosRequestConfig = {
    data: request.data,
    params: request.params ?? {},
    method: request.method,
    url: route,
    headers: isFileUpload
      ? { "Content-Type": "multipart/form-data", ...request.headers }
      : { "Content-Type": "application/json", ...request.headers },
    responseType: request.responseType,
  };

  let response;
  try {
    if (request.noAuth) {
      response = await noAuthAxiosInstance.request(options);
    } else {
      response = await axiosInstance.request(options);
    }
  } catch (err) {
    const error = err as AxiosError;
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      throw new Error((error.response.data as any).message);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
      throw new Error("Failed to reach server");
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
      throw new Error("Failed to send request");
    }
  }
  return response?.data;
};

export default apiRequest;

export const fetcherV2 = <T>([url, params]: any[]): Promise<T> =>
  apiRequest(url, { method: "GET", params });
