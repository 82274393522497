import "@/styles/globals.css";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import { buildDefaultTheme } from "@/styles/theme";
import type { AppProps } from "next/app";
import { ThemeProvider } from "styled-components";
import NextNProgress from "nextjs-progressbar";
import { hslToHex } from "@/lib/colors";
import { Inter } from "next/font/google";
import { useEffect, useRef } from "react";
import { AuthProvider } from "@/hooks/auth.provider";
import { Toaster } from "sonner";

const inter = Inter({
  subsets: ["latin"],
});

export default function App({ Component, pageProps }: AppProps) {
  const theme = buildDefaultTheme();

  const logRocketRef = useRef<boolean>(false);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      !logRocketRef.current &&
      process.env.NEXT_PUBLIC_STAGE === "prod"
    ) {
      LogRocket.init("7mxyxp/donor-portal", {
        dom: {
          inputSanitizer: true,
        },
        network: {
          requestSanitizer: (request) => {
            // Skip the body of request to Basiq to respect the privacy of donor
            if (request.url.toLowerCase().indexOf("basiq.io") !== -1) {
              // scrub out the body
              request.body = undefined;
            }

            return request;
          },
        },
      });
      setupLogRocketReact(LogRocket);
      logRocketRef.current = true;
    }
  }, []);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <NextNProgress
          color={hslToHex(theme.colors.secondary.accent)}
          startPosition={0.3}
          stopDelayMs={200}
          height={3}
          showOnShallow
          options={{ easing: "ease", speed: 500 }}
        />
        <style jsx global>{`
          html {
            font-family: ${inter.style.fontFamily};
          }
          body {
            margin: 0px;
          }
          * {
            box-sizing: border-box;
          }
        `}</style>
        <Toaster position="top-right" />
        <Component {...pageProps} />
      </ThemeProvider>
    </AuthProvider>
  );
}
