import { API_V2 } from "@/config/api";
import apiRequest from "./api";
import { Donors } from "@/types/models/Donors";

export const routes = {
  me: `${API_V2}/donors/me`,
  listAccounts: `${API_V2}/donors/me/accounts`,
  bank: `${API_V2}/donors/me/bank`,
  uploadAvatar: `${API_V2}/donors/me/avatar`,
  delete: `${API_V2}/donors/me/avatar`,
  update: `${API_V2}/donors/me`,
};

export const uploadDonorAvatar = (data: FormData) =>
  apiRequest<void>(routes.uploadAvatar, {
    method: "POST",
    data,
  });

export const deleteDonorAvatar = () =>
  apiRequest<void>(routes.uploadAvatar, {
    method: "DELETE",
  });

export interface UpdateDonorRequest {
  firstName: string;
  lastName: string;
  phone?: string;
  address?: string;
}

export const updateDonor = (data: UpdateDonorRequest): Promise<Donors> =>
  apiRequest<Donors>(routes.update, {
    method: "POST",
    data,
  });
